/* Section page brag carousel */
.ui-brag-carousel {
    max-width: 1030px;
    margin: 0 auto 90px;
    text-align: center;
}
.ui-brag-carousel .section-title,
.ui-brag-carousel .perex {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: SansOri, SansOriFallback, sans-serif;
}
.ui-brag-carousel .section-title {
    font-size: var(--font-size-h4);
    font-weight: bold;
    line-height: 1;
    margin-bottom: 30px;
    text-align: center;
}
.ui-brag-carousel .perex {
    display: inline-block;
    max-width: 660px;
    margin: 0 auto;
    font-size: var(--font-size-body1);
    line-height: 1.36;
    margin-bottom: 35px;
    text-align: center;
    color: var(--grey-a700);
}
.ui-brag-carousel .item,
.ui-brag-carousel .rsOverflow,
.ui-brag-carousel .rsContainer,
.ui-brag-carousel .rsSlide,
.ui-brag-carousel .rsContent {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
}
.ui-brag-carousel .item {
    position: relative;
    font-size: 0;
}
.ui-brag-carousel .rsContent {
    font-size: 0;
}
.ui-brag-carousel .item .ui-box-brag {
    display: inline-block;
    width: calc(50% - 15px);
    height: auto;
    margin: 7.5px 0;
    padding: 0;
    font-size: 0;
}
.ui-brag-carousel .item .ui-box-brag:nth-of-type(odd) {
    margin-right: 7.5px;
}
.ui-brag-carousel .item .ui-box-brag:nth-of-type(even) {
    margin-left: 7.5px;
}
.ui-brag-carousel .item .ui-box-brag:nth-child(4n + 1) {
    background-color: var(--secondary-light);
}
.ui-brag-carousel .item .ui-box-brag:nth-child(4n + 2) {
    background-color: var(--warning-main);
}
.ui-brag-carousel .item .ui-box-brag:nth-child(4n + 3) {
    background-color: var(--info-main);
}
.ui-brag-carousel .item .ui-box-brag:nth-child(4n + 4) {
    background-color: var(--secondary-main);
}
.ui-brag-carousel .item .ui-box-brag .content,
.ui-brag-carousel .item .ui-box-brag .image {
    position: relative;
    display: inline-block;
    width: 50%;
    padding-top: 50%;
    vertical-align: middle;
    font-size: initial;
}
.ui-brag-carousel .item .ui-box-brag:nth-of-type(odd) .content {
    margin-right: -50%;
}
.ui-brag-carousel .item .ui-box-brag:nth-of-type(odd) .image {
    margin-left: -50%;
}
.ui-brag-carousel .item .ui-box-brag .square-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.ui-brag-carousel .item .ui-box-brag .image .square-wrap {
    overflow: hidden;
}
.ui-brag-carousel .item .ui-box-brag .vertical-container {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    padding: 0 15px;
}
.ui-brag-carousel .item .ui-box-brag .image img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}
.ui-brag-carousel .item .ui-box-brag .headline-serif,
.ui-brag-carousel .item .ui-box-brag .description {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: SansOri, SansOriFallback, sans-serif;
}
.ui-brag-carousel .item .ui-box-brag .headline-serif {
    overflow: hidden;
    color: var(--common-white);
    font-size: var(--font-size-heading2);
    font-weight: bold;
}
.ui-brag-carousel .item .ui-box-brag .description {
    margin-top: 10px;
    font-size: var(--font-size-body1);
    line-height: 1.36;
    color: var(--common-white);
}

@media only screen and (min-width: 768px) {
    .ui-brag-carousel .item .ui-box-brag .headline-serif {
        font-size: 5rem;
    }
}

/* BP 1-2 */
@media only screen and (max-width: 767px) {
    .ui-brag-carousel .item .ui-box-brag {
        width: 100%;
    }
    .ui-brag-carousel .item .ui-box-brag:nth-of-type(1) {
        margin-right: 0;
    }
    .ui-brag-carousel .item .ui-box-brag:nth-of-type(2) {
        margin-left: 0;
    }
    .ui-brag-carousel .rsNav {
        bottom: -20px;
    }
    .ui-brag-carousel .section-title {
        font-size: var(--font-size-h5);
        line-height: 1.25;
    }
}

/* Theme specifics*/
.theme-purple .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 1) {
    background-color: var(--background-new-orleans);
}
.theme-purple .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 2) {
    background-color: var(--background-soft-amber);
}
.theme-purple .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 3) {
    background-color: var(--background-wafer);
}
.theme-purple .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 4) {
    background-color: var(--warning-main);
}

.theme-violet .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 1) {
    background-color: var(--secondary-main);
}
.theme-violet .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 2) {
    background-color: var(--info-main);
}
.theme-violet .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 3) {
    background-color: var(--background-chatelle);
}
.theme-violet .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 4) {
    background-color: var(--background-wafer);
}

.theme-yellow .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 1) {
    background-color: var(--background-wafer);
}
.theme-yellow .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 2) {
    background-color: var(--warning-main);
}
.theme-yellow .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 3) {
    background-color: var(--info-main);
}
.theme-yellow .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 4) {
    background-color: var(--secondary-main);
}

.theme-laurel-green .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 1) {
    background-color: #d9dbbc;
}
.theme-laurel-green .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 2) {
    background-color: #a3b3c3;
}
.theme-laurel-green .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 3) {
    background-color: #b6bba9;
}
.theme-laurel-green .ui-brag-carousel .item .ui-box-brag:nth-child(4n + 4) {
    background-color: #d7e1ea;
}
